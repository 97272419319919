import React from "react";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { Link } from "gatsby";

const TextWrapper = styled.div`
  max-width: 1440px;
  margin-bottom: 3rem;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  // @media only screen and (min-width: 1920px){
  //   position: relative;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   /* display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-direction: column; */
  // }

  @media only screen and (max-width: 768px){
    margin-top: 4rem;
  }

  @media only screen and (max-width: 500px){
    margin-top: 2rem;
  }
`;

// Add YouTube embed container styles
const YouTubeContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin: 1.5rem auto;
  }
`;

// YouTube ID extractor helper function
const getYouTubeId = (url) => {
  if (!url) return null;
  
  // Match different YouTube URL formats
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  
  return (match && match[2].length === 11) ? match[2] : null;
};

// YouTube embed component
const YouTubeEmbed = ({ id }) => {
  if (!id) return null;
  
  return (
    <YouTubeContainer>
      <iframe
        src={`https://www.youtube.com/embed/${id}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </YouTubeContainer>
  );
};

const RichTextContainer = styled.div`
  /* From paste-2.txt - overrides first file's padding */
  padding: 0 3rem;
  margin: 0 auto;
  
  /* From paste.txt - preserved because no conflict */
  max-width: 1440px;
  width: 100%;

  /* From paste-2.txt */
  .client_name{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 45px !important;
    line-height: 105.4%;
    letter-spacing: -0.005em;
    color: var(--blue);
    text-align: left;
    margin-bottom: 2rem;

    @media only screen and (max-width: 1250px){
      font-size: 40px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 35px;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 40px;
      text-align: center;
    }

    @media only screen and (max-width: 500px){
      font-size: 40px;
      line-height: 105.4%;
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  /* From paste-2.txt */
  .case_study_text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.005em;
    color: var(--orange);
    text-align: left;
    margin-bottom: 1rem;

    @media only screen and (max-width: 768px){
      text-align: center;
    }
  }

  /* From paste-2.txt - overrides first file's .rich_paragraph */
  .rich_paragraph{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    color: #000000;
    text-align: left;
    margin-bottom: 2rem;

    @media only screen and (max-width: 1250px){
      font-size: 22px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 20px;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      font-size: 18px;
      text-align: center;
      margin-bottom: 2rem;
      padding: 0 6.8rem;
    }
    
    @media only screen and (max-width: 500px){
      font-size: 18px;
      text-align: center;
      margin-bottom: 2rem;
      padding: 0 1.4rem;
    }
  }

  /* From paste-2.txt */
  li{
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  /* From paste-2.txt - overrides first file's .rich_ol */
  .rich_ol{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    color: var(--blue);
    position: relative;
    list-style-type: auto;

    @media only screen and (max-width: 1250px){
      font-size: 25px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px;
      text-align: left;
      padding: 0 3rem;
      line-height: 102%;
    }

    @media only screena and (max-width: 500px){
      font-size: 25px;
      padding: 0 1rem;
    }
  }

  /* From paste-2.txt */
  .rich_ol > li{
    margin-bottom: 1rem;
  }

  /* From paste-2.txt */
  .rich_ol > li > p{
    @media only screen and (min-width: 769px){
      margin-left: 1rem;
      margin-bottom: 0rem;
    }

    @media only screen and (min-width: 501px) and (max-width: 768px){
      padding: 0 12px;
    }

    @media only screen and (max-width: 768px){
      margin-top: 1rem;
    }
  }

  /* From paste-2.txt - overrides first file's .rich_ul */
  .rich_ul{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 152.9%;
    letter-spacing: -0.005em;
    color: var(--blue);
    list-style-type: square;
    padding-left: 5rem;
    list-style-type: disc;

    @media only screen and (max-width: 1250px){
      font-size: 25px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px;
    }

    @media only screen and (max-width: 768px){
      font-size: 18px;
      text-align: left;
      padding: 0 3rem;
    }

    @media only screen and (max-width: 500px){
      padding: 0rem;
    }
  }

  /* From paste-2.txt */
  .rich_ul li> p{
     margin-bottom: 0rem;
  }

  /* From paste-2.txt */
  .rich_ul li::marker{
    color: var(--blue);
  }

  /* From paste.txt - preserved because no direct conflict */
  .rich_h2{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 129.9%;
    text-align: center;
    letter-spacing: -0.005em;
    color: var(--blue);
    text-align:${({ textAlignment }) => `${textAlignment}`};
    margin-bottom: 3rem;

    @media only screen and (min-width: 640px){
      font-size: 24px;
    }

    @media only screen and (min-width: 768px){
      font-size: 36px;
    }
  }

  /* From paste.txt - preserved because no direct conflict */
  .rich_h3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130.4%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    background-color: var(--blue);
    padding:0 1rem;
    width: 20%;
    margin-bottom: 3rem;

    @media only screen and (max-width: 1250px){
      font-size: 35px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 32px;
    }

    @media only screen and (max-width: 768px){
      font-size: 30px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 25%;
      
      padding: 0.5rem 1rem;
    }

    @media only screen and (max-width: 500px){
      font-size: 25px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 45%;
      margin-bottom: 1rem;
      padding: 0.5rem 1rem;
    }
  }

  /* From paste.txt - preserved because no direct conflict */
  .rich_h4{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 105.4%;
    letter-spacing: -0.005em;
    text-align: center;
    color: var(--blue);
    margin-bottom: 1rem;

    @media only screen and (min-width: 640px){
      font-size: 24px;
    }

    @media only screen and (min-width: 768px){
      font-size: 36px;
      text-align: left;
    }
  }

  /* From paste.txt - preserved because no direct conflict */
  .rich_h5{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 105.4%;
    letter-spacing: -0.005em;
    text-align: ${({ textAlignment }) => `${textAlignment}`};
    color: var(--blue);
    margin-bottom: 3rem;

    @media only screen and (max-width: 1250px){
      font-size: 28px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 22px;
    }

    @media only screen and (max-width: 768px){
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 152.9%;
      text-align: center;
      letter-spacing: -0.005em;
      padding: 0 6.2rem;
      margin-bottom: 0rem;
    }

    @media only screen and (max-width: 500px){
      padding: 0;
    }
  }

  /* From paste.txt - preserved because no direct conflict */
  .rich_ol strong{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 152.9%;
    /* or 46px */
    color: var(--blue);
    letter-spacing: -0.005em;
    list-style-type: auto;

    @media only screen and (max-width: 1250px){
      font-size: 22px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 20px;
    }

    @media only screen and (max-width: 768px){
      font-size: 20px;
    }

    @media only screen and (max-width: 500px){
      font-size: 18px;
    }
  }

  /* From paste.txt - preserved because no direct conflict */
  .linebreak--mobile{
    @media only screen and (min-width: 769px){
      display: none;
    }
  }

  /* From paste.txt - preserved because no direct conflict */
  .bubble_container{
    margin-top: 3rem;
    margin-bottom: 5rem;

    @media only screen and (max-width: 500px){
      margin-bottom: 3rem;
    }
  }

  /* From paste.txt - preserved because no direct conflict */
  .bubble {
    min-width: 200px;
    background-color: var(--blue);
    max-width: 650px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 2rem;
    border-radius: 1rem;

    @media only screen and (max-width: 768px){
      max-width: 380px;
    }

    @media only screen and (max-width: 500px){
      padding: 1rem;
      border-radius: 0.5rem;
    }
  }

  /* From paste.txt - preserved because no direct conflict */
  .pointer {
    height: 40px;
    width: 40px;
    background-color: var(--blue);
    margin: 0 auto;
    transform: rotate(45deg);
    margin-top: -22px;
    position: relative;
    left: calc(0.5vw - 13rem);
    z-index: -1;

    @media only screen and (max-width: 768px){
      height: 30px;
      width: 30px;
      left: calc(0.5vw - 7rem);
    }

    @media only screen and (max-width: 500px){
      left: calc(0.5vw - 5rem);
    }
  }

  /* From paste.txt - preserved because no direct conflict */
  .bubble h6{
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 500;
    font-size: 28px;
    line-height: 142.4%;
    text-align: center;
    letter-spacing: -0.005em;
    color: #ffffff;
    margin: 0;

    @media only screen and (max-width: 1250px){
      font-size: 24px;
    }

    @media only screen and (max-width: 1024px){
      font-size: 20px;
    }

    @media only screen and (max-width: 768px){
      font-size: 16px;
    }
  }

  /* From paste-2.txt - overrides first file's media query */
  @media only screen and (max-width: 768px){
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    margin-bottom: 5rem;
  }

  @media only screen and (max-width: 500px){
    padding: 0 12px;
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;

  .lets_chat_button{
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    margin-bottom: 5rem;
    
    @media only screen and (min-width: 1025px){
      max-width: 316px;
      width: 100%
    }
  }
`;

const Text = ({ data }) => {
  const { richText, textAlignment, isLastComponent } = data;
  const json_text = JSON.parse(richText.raw);
  
  // Enhanced rich text options with YouTube support
  const RICHTEXT_OPTIONS = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}<br className='linebreak--mobile'></br></strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        // Check if paragraph contains only text that might be a YouTube URL
        if (node.content && node.content.length === 1 && node.content[0].nodeType === 'text') {
          const text = node.content[0].value?.trim();
          
          if (text && (
              text.includes('youtube.com/watch') || 
              text.includes('youtu.be/') ||
              text.includes('youtube.com/embed/')
            )) {
            const videoId = getYouTubeId(text);
            if (videoId) {
              return <YouTubeEmbed id={videoId} />;
            }
          }
        }
        return <p className='rich_paragraph'>{children}</p>;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 className='rich_h2'>{children}</h2>;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <h3 className='rich_h3'>{children}</h3>
        );
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return <h4 className='rich_h4'>{children}</h4>;
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return <h5 className='rich_h5'>{children}</h5>;
      },
      [BLOCKS.HEADING_6]: (node, children) => {
        return (
          <div className='bubble_container'>
            <div className="bubble">
              <h6>{children}</h6>
            </div>
            <div className="pointer"></div>
          </div>
        );
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className='rich_ul'>{children}</ul>;
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return <ol className='rich_ol'>{children}</ol>;
      },
      // Handle hyperlinks that might be YouTube URLs
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data;
        
        // Check if link is a YouTube URL
        if (uri.includes('youtube.com/watch') || uri.includes('youtu.be/')) {
          const videoId = getYouTubeId(uri);
          if (videoId) {
            return <YouTubeEmbed id={videoId} />;
          }
        }
        
        // Regular link
        return <a href={uri} target="_blank" rel="noopener noreferrer">{children}</a>;
      },
    },
  };
  
  return (
    <TextWrapper>
      <RichTextContainer textAlignment={textAlignment}>
        {documentToReactComponents(json_text, RICHTEXT_OPTIONS)}
      </RichTextContainer>
      {isLastComponent && <ButtonContainer>
        <Link to="/contact-us"><button className='hire_button lets_chat_button'>Let&apos;s Chat</button></Link>
      </ButtonContainer>}
    </TextWrapper>
  );
};

export default Text;